import "@hotwired/turbo-rails";
import "./controllers";
import { initMap as initAutocompleteMap } from "./packs/autocompleteMap";
import { initIndexMap } from "./packs/indexMap";
import { loadBicyclesData } from "./packs/indexMap";
import { initCategoryMap } from "./packs/indexCategory.js";
import { initCityMap } from "./packs/indexCity.js";
import { initSimpleMap } from "./packs/simpleMap";
import { estimateCost } from "./packs/estimate_cost";
import "./packs/backToTopBtn.js";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";


let mapsInitialized = {
  autocomplete: false,
  index: false,
  simple: false
};

function initMaps() {
  if (document.getElementById('location-input') && !mapsInitialized.autocomplete) {
    initAutocompleteMap();
    mapsInitialized.autocomplete = true;
  }
  if (document.getElementById('index-map') && !mapsInitialized.index) {
    initIndexMap();
    mapsInitialized.index = true;
  }
  if (document.getElementById('gmp-map') && !mapsInitialized.simple) {
    initSimpleMap();
    mapsInitialized.simple = true;
  }
  if (document.getElementById('category-map') && !mapsInitialized.simple) {
    initCategoryMap();
    mapsInitialized.simple = true;
  }
  if (document.getElementById('city-map') && !mapsInitialized.simple) {
    initCityMap();
    mapsInitialized.simple = true;
  }
}

function initConditionalScripts() {
  if (document.getElementById('rental_form')) {
    estimateCost();
  }
}

document.addEventListener('turbo:load', function () {
  initConditionalScripts();
  initMaps();
});

document.addEventListener('turbo:before-visit', function () {
  mapsInitialized = {
    autocomplete: false,
    index: false,
    simple: false
  };
});

document.addEventListener("DOMContentLoaded", function() {
  // Affiche la modal si le consentement aux cookies n'a pas encore été donné
  if (!getCookie('cookiesConsent')) {
    var cookiesModal = document.getElementById('fontModal'); // Utilisez le même id ou changez selon votre HTML
    if (cookiesModal) {
      cookiesModal.classList.add('show');
      cookiesModal.style.display = 'block';
      document.body.classList.add('modal-open');
    }
  }

  var acceptButton = document.getElementById('yesButton');
  var refuseButton = document.getElementById('noButton');

  if (acceptButton) {
    acceptButton.addEventListener('click', function() {
      closeCookiesModal();
      setCookie('cookiesConsent', 'true', 365); // L'utilisateur accepte les cookies
      // Chargez ici la balise Google Analytics si l'utilisateur accepte
      loadGoogleAnalytics(); // Fonction pour charger Google Analytics
      loadGoogleTagManager(); // Fonction pour charger Google Tag Manager
    });
  }

  if (refuseButton) {
    refuseButton.addEventListener('click', function() {
      closeCookiesModal();
      setCookie('cookiesConsent', 'false', 365); // L'utilisateur refuse les cookies
    });
  }
});

function loadGoogleAnalytics() {
  // Google tag (gtag.js)
  var script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-3MNFCR23R5';
  document.head.appendChild(script);

  script.onload = function() {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-3MNFCR23R5');
  };
}

function loadGoogleTagManager() {
  (function(w,d,s,l,i){
    w[l]=w[l]||[];
    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
    j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NH4M4N3B');
}

function closeCookiesModal() {
  var cookiesModal = document.getElementById('fontModal'); // Utilisez le même id ou changez selon votre HTML
  if (cookiesModal) {
    cookiesModal.classList.remove('show');
    cookiesModal.style.display = 'none';
    document.body.classList.remove('modal-open');
  }
}

// Fonctions utilitaires pour gérer les cookies
function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + '; path=/';
}

function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

document.addEventListener('scroll', function() {
  var toggleButton = document.getElementById('toggleButtonContainer');
  var footer = document.getElementById('footer');
  
  if (toggleButton && footer) {
    var footerRect = footer.getBoundingClientRect(); // Obtenir la position du footer
    var footerVisible = footerRect.top < window.innerHeight; // Vérifie si le footer est visible
  
    if (footerVisible) {
      toggleButton.style.display = 'none'; // Cache le bouton
    } else {
      toggleButton.style.display = ''; // Réaffiche le bouton
    }
  }
});

document.addEventListener("turbo:load", initializeFlatpickr);
document.addEventListener("turbo:render", initializeFlatpickr);

function isDayDisabled(date, reservedDates) {
  const dateString = flatpickr.formatDate(date, "Y-m-d");
  const reservation = reservedDates.find(r => r.date === dateString);
  if (!reservation) return false;
  return reservation.disabledHours.some(([start, end]) => start < 14 && end >= 14);
}


function initializeFlatpickr() {
  const startDateInput = document.getElementById("start-date-input");
  const endDateInput = document.getElementById("end-date-input");

  if (!startDateInput || !endDateInput) {
    // Si l'un des éléments n'existe pas, sortir de la fonction
    return;
  }
  
  const reservedDates = JSON.parse(startDateInput.getAttribute('data-reserved-dates') || '[]');

  const startFlatpickr = flatpickr(startDateInput, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate: "today",
      disable: [
          function(date) { return isDayDisabled(date, reservedDates); }
      ],
      minuteIncrement: 60,
      time_24hr: true,
      onChange: function(selectedDates, dateStr, instance) {
        if (endDateInput._flatpickr) {
            endDateInput._flatpickr.set('minDate', dateStr);
        }
      },
      onDayCreate: function(dObj, dStr, fp, dayElem) {
        dayElem.addEventListener("click", function() {
          setTimeout(() => {
            fp.close(); // Fermer le sélecteur de date de début
            if (endDateInput._flatpickr) {
              endDateInput._flatpickr.open(); // Ouvrir le sélecteur de date de fin
            }
          }, 0);
        });
      }  
  });

  const endFlatpickr = flatpickr(endDateInput, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate: "today",
      disable: [
          function(date) { return isDayDisabled(date, reservedDates); }
      ],
      minuteIncrement: 60,
      time_24hr: true,
      onChange: function(selectedDates, dateStr, instance) {
      },
      onDayCreate: function(dObj, dStr, fp, dayElem) {
        dayElem.addEventListener("click", function() {
          setTimeout(() => {
            fp.close(); // Fermer le sélecteur de date de fin
          }, 0);
        });
      }  
  });

  // Ajouter des gestionnaires d'événements pour les boutons de réinitialisation
  const resetStartDateButton = document.getElementById("reset-start-date");
  const resetEndDateButton = document.getElementById("reset-end-date");
  const resetCityButton = document.getElementById("reset-city");
  const cityInput = document.getElementById("city-input");

  if (resetStartDateButton) {
    resetStartDateButton.addEventListener("click", function() {
      startFlatpickr.clear();
      if (endDateInput._flatpickr) {
        endDateInput._flatpickr.set('minDate', "today");
      }
    });
  }

  if (resetEndDateButton) {
    resetEndDateButton.addEventListener("click", function() {
      endFlatpickr.clear();
    });
  }

  if (resetCityButton) {
    resetCityButton.addEventListener("click", function() {
      cityInput.value = '';
      const isListView = document.getElementById('map-container').classList.contains('hidden-map');
      initIndexMap();
      initCategoryMap();
      if (isListView) {
        loadBicyclesData(); // Recharger la liste des vélos
      }
    });
  }
}

document.addEventListener('turbo:load', function() {
  const toggleFontButton = document.getElementById('toggleFontButton');

  if (toggleFontButton) {
    toggleFontButton.classList.add('opendyslexic-font');
    toggleFontButton.addEventListener('click', toggleDyslexicFont);
  }
});

import "@hotwired/turbo-rails";